<template>
  <div>
    <b-card no-body>
      <b-tabs pills class="pt-1 pl-1 pr-1">
        <b-tab
            title="Dashboard"
            @click="$router.push({name: 'dashboard'})"
        />
        <b-tab
            title="Apps"
            active
            @click="$router.push({name:'apps'})"
        />
        <b-tab
            title="Stats"
        />
        <b-tab
            title="Docs"
        />
        <b-tab
            title="Plans"
        />
        <b-tab
            title="Notifications"
        />
      </b-tabs>

    </b-card>
    <b-card-group deck>
      <b-card class="col-md-6 cursor-pointer" @click="test">
        <b-card-text class="font-large-1 font-weight-bold">vardump</b-card-text>
        <b-card-group deck>
          <b-card class="col-sm-6 text-center">
            <b-card-text class="font-large-2 font-weight-bold" style="color: #28c76f">60</b-card-text>
            <b-card-text class="mt-2">Connections</b-card-text>
          </b-card>
          <b-card class="col-sm-6 text-center">
            <b-card-text class="font-large-2 font-weight-bold" style="color: #28c76f">200k</b-card-text>
            <b-card-text class="mt-2">Messages</b-card-text>
          </b-card>
        </b-card-group>
        <b-card-text class="font-small-1">* 10 min average data, click to see realtime stats</b-card-text>
      </b-card>
    </b-card-group>


  </div>
</template>

<script>
import { BCard, BCardText, BCardGroup, BLink, BTab} from 'bootstrap-vue'
import { BTabs, BButton, BModal } from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BCardGroup,
    BButton,
    BModal
  },
  methods:{
    test(){
      alert("eee");
    },
  }
}
</script>

<style scoped>

</style>
